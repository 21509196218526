import Web3 from 'web3';
import energiExtension from '@energi/web3-ext';
import {createEvent, createStore} from 'effector';
import {parseEnode} from '../lib/helpers/key-parser';
import {networkOptions} from '../lib/constants/network-info';
import {getStatusText} from '../lib/helpers/masternode-status-text';
import blockPolling from './block-polling';

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const $lastBlock = blockPolling.$lastBlock;

let polling = false,
    pollingBalance = false;

const toBN = Web3.utils.toBN,
    isValidAddress = Web3.utils.isAddress,
    etherWeiBN = toBN(Web3.utils.unitMap.ether),
    collateralStep = '1000',
    BN0 = toBN('0'),
    BN100k = toBN('100000000000000000000000'),
    BNCollateralStep = toBN(collateralStep),
    web3 = new Web3(networkOptions.networkAddress),
    DENOUNCED = 'denounced';

energiExtension.extend(web3);

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const emptyStore = {
    address: '',
    enode: '',
    validAddress: false,
    validEnode: false,
    collateral: toBN('0'),
    balance: toBN('0'),
    masternodeStatus: '',
    canDeposit: false,
    canWithdraw: false,
};

const on = {
    changeAddress: createEvent(),
    changeEnode: createEvent(),
    change: createEvent(),
    clear: createEvent()
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* Tries to get the masternode owneraddress based upon an enode string.
* Updates  and masternode status.
* If address is valid, it updates the current $store.address and balances.
*
* @method checkAddressAndBalance
* @param enodeUrl {String} the masternode enode string
* @protected
* @since 0.4.0
*/
const checkAddressAndBalance = async enodeUrl => {
    const masternodeAddress = getMasternodeAddress(enodeUrl);
    let masternodeInfo, address, data, mnrg, nrg, newState, collateral, balance;

    if (masternodeAddress) {
        masternodeInfo = await web3.masternode.masternodeInfo(masternodeAddress); // lookup by masternodeAddress
    }
    if (masternodeInfo && masternodeInfo.owner) {
        address = masternodeInfo.owner;
        data = await Promise.all([
            web3.masternode.collateralBalance(address),
            web3.eth.getBalance(address),
        ]);
        mnrg = data[0];
        nrg = data[1];
        collateral = mnrg.balance;
        balance = toBN(nrg);
        newState = {
            collateral,
            balance,
            address: address,
            validAddress: true,
            canDeposit: balance.gte(BNCollateralStep.mul(etherWeiBN)) && collateral.lt(BN100k),
            canWithdraw: collateral.gt(BN0),
        };
        newState.masternodeStatus = getStatusText(masternodeInfo);
        startPollingBalance(); // because the address is valid
        on.change(newState);
    }
    else {
        // if valid address then check if address is already announced by another owner:
        address = $store.getState().address;
        if (address) {
            masternodeInfo = await web3.masternode.masternodeInfo(address); // lookup by owner
        }
        if (masternodeInfo && masternodeInfo.owner) {
            on.change({
                masternodeStatus: 'address occupied'
            });
        }
    }
};

/**
* Tries to get the masternode owneraddress based upon a masternode owner address.
* Also updates the current $store balances and masternode status.
*
* @method checkEnodeAndBalance
* @param address {String} with error property
* @protected
* @since 0.4.0
*/
const checkEnodeAndBalance = async address => {
    const data = await Promise.all([
            web3.masternode.collateralBalance(address),
            web3.eth.getBalance(address),
            web3.masternode.masternodeInfo(address) // lookup by owner
        ]),
        mnrg = data[0],
        nrg = data[1],
        collateral = mnrg.balance,
        balance = toBN(nrg);
    let masternodeInfo = data[2],
        newState = {
            collateral,
            balance,
            canDeposit: balance.gte(BNCollateralStep.mul(etherWeiBN)) && collateral.lt(BN100k),
            canWithdraw: collateral.gt(BN0),
        },
        enodeUrl, masternodeAddress;

    if (masternodeInfo) {
        newState.enode = masternodeInfo.enode;
        newState.validEnode = true;
        newState.masternodeStatus = getStatusText(masternodeInfo);
    }
    else {
        // if valid enode then check if enode is already announced by another owner:
        enodeUrl = $store.getState().enode;
        masternodeAddress = getMasternodeAddress(enodeUrl);
        if (masternodeAddress) {
            masternodeInfo = await web3.masternode.masternodeInfo(masternodeAddress); // lookup by masternode address
        }
        if (masternodeInfo && masternodeInfo.owner) {
            newState.masternodeStatus = 'anothers masternode';
        }
        else {
            newState.masternodeStatus = masternodeAddress ? DENOUNCED : '';
        }
    }
    on.change(newState);
};

/**
* Clones a store, so that it can be used as a new immutable state
*
* @method cloned
* @param store {Object} store to be cloned
* @protected
* @since 0.4.0
*/
const cloned = store => {
    const {address, enode, validAddress, validEnode, masternodeStatus} = store;
    return {
        address,
        enode,
        validAddress,
        validEnode,
        collateral: toBN(store.collateral.toString()),
        balance: toBN(store.balance.toString()),
        masternodeStatus,
    };
};

/**
* Retrieves the balance and collateral of the current $store.address
* and updates $store with the latest values.
*
* @method fetchBalance
* @param [updateMasternodeInfo] {Boolean} whether to also update masternode info
* @protected
* @since 0.4.0
*/
const fetchBalance = async updateMasternodeInfo => {
    let data, mnrg, nrg, collateral, balance, newState, masternodeInfo, enodeUrl, masternodeAddress;
    const address = $store.getState().address;
    if (address && isValidAddress(address)) {
        data = await Promise.all([
            web3.masternode.collateralBalance(address),
            web3.eth.getBalance(address),
        ]);
        mnrg = data[0];
        nrg = data[1];
        collateral = mnrg.balance;
        balance = toBN(nrg);
        newState = {
            collateral,
            balance,
            canDeposit: balance.gte(BNCollateralStep.mul(etherWeiBN)) && collateral.lt(BN100k),
            canWithdraw: collateral.gt(BN0),
        };
        if (updateMasternodeInfo) {
            enodeUrl = $store.getState().enode;
            masternodeAddress = getMasternodeAddress(enodeUrl);
            newState.validEnode = !!masternodeAddress;
            if (masternodeAddress) {
                masternodeInfo = await web3.masternode.masternodeInfo(masternodeAddress); // lookup by masternode address
            }
            if (masternodeInfo) {
                newState.masternodeStatus = (masternodeInfo.owner === address.toLowerCase()) ? getStatusText(masternodeInfo) : 'anothers masternode';
            }
            else {
                newState.masternodeStatus = newState.validEnode ? DENOUNCED : '';
            }
        }
        on.change(newState);
    }
};

/**
* Gets the masternode address that belongs to an enode.
*
* @method getMasternodeAddress
* @param enodeUrl {String} the masternode enode string
* @protected
* @return {String} Masternode address, if enode is valid
* @since 0.4.0
*/
const getMasternodeAddress = enodeUrl => {
    const parsed = parseEnode(enodeUrl);
    return (parsed && parsed.masternodeAddress && isValidAddress(parsed.masternodeAddress)) && parsed.masternodeAddress;
};

/**
* Checks if an enode string is a valid String
*
* @method isValidEnode
* @param enodeUrl {String} the masternode enode string
* @protected
* @return {Boolean} whether the enode string is valid
* @since 0.4.0
*/
const isValidEnode = enodeUrl => {
    return !!getMasternodeAddress(enodeUrl);
};

/**
* Activates the polling process.
*
* @method startPolling
* @protected
* @since 0.4.0
*/
const startPolling = () => {
    polling = true;
};

/**
* Activates the pollingBalance process.
*
* @method startPollingBalance
* @protected
* @since 0.4.0
*/
const startPollingBalance = () => {
    pollingBalance = true;
};

/**
* Deactivates the polling process.
*
* @method stopPolling
* @protected
* @since 0.4.0
*/
const stopPolling = () => {
    polling = false;
};

/**
* Deactivates the pollingBalance process.
*
* @method stopPollingBalance
* @protected
* @since 0.4.0
*/
const stopPollingBalance = () => {
    pollingBalance = false;
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const $store = createStore(cloned(emptyStore));

$store.on(on.changeAddress, (state, value) => {
    if (state.address === value) {
        return;
    }
    let newState = cloned(state);
    newState.address = value;
    newState.validAddress = isValidAddress(value);
    newState.canDeposit = false;
    newState.canWithdraw = false;
    // next, in case of valid address, we check for enode:
    if (newState.validAddress) {
        startPollingBalance(); // because the address is valid
        checkEnodeAndBalance(value); // do not await!
    }
    else {
        newState.collateral = toBN('0');
        newState.balance = toBN('0');
        stopPollingBalance(); // because the address is invalid
    }
    return newState;
});

$store.on(on.changeEnode, (state, value) => {
    let newState = cloned(state);
    newState.enode = value;
    newState.validEnode = isValidEnode(value);
    // next, in case of valid address, we check for enode:
    if (newState.validEnode) {
        checkAddressAndBalance(value); // do not await!
    }
    else {
        newState.masternodeStatus = '';
    }
    return newState;
});

$store.on(on.change, (state, value) => {
    let newState = cloned(state);
    const keys = Object.keys(value);
    keys.forEach(key => {
        newState[key] = value[key];
    });
    return newState;
});

$store.on(on.clear, () => cloned(emptyStore));

$lastBlock.watch(() => {
    if (polling || pollingBalance) {
        fetchBalance(polling);
    }
});

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* @property masternodeOwner
*     @property {Object} masternodeListing.$store all Masternode Owner data
*         @property {String} address
*         @property {String} enode
*         @property {Boolean} validAddress
*         @property {Boolean} validEnode
*         @property {BigNumber} collateral
*         @property {BigNumber} balance
*         @property {String} masternodeStatus
*         @property {Boolean} canDeposit
*         @property {Boolean} canWithdraw
*     @property {Object} masternodeListing.on
*         @property {Function} masternodeListing.on.changeAddress changes $store.address
*         @property {Function} masternodeListing.on.changeEnode changes $store.enode
*         @property {Function} masternodeListing.on.change changes $store with a complete new state definition
*         @property {Function} masternodeListing.on.clear clears $store state
*     @property {Object} masternodeListing.run
*         @property {Function} masternodeListing.run.updateBalance param ownerAddress
* @since 0.4.0
*/
const masternodeOwner = {
    $store,
    on,
    run: {
        startPolling,
        stopPolling,
        updateBalance: fetchBalance,
    }
};

export default masternodeOwner;
