const MAINNET_REMOTE_GEN3 = 'https://nodeapi.energi.network',
    TESTNET_REMOTE_GEN3 = 'https://nodeapi.test.energi.network',
    MAINNET_NETWORK_ID = 39797,
    MAINNET_CHAIN_ID = 39797,
    TESTNET_NETWORK_ID = 49797,
    TESTNET_CHAIN_ID = 49797,
    MAINNET_WALLET_URL = 'https://wallet.energi.network',
    TESTNET_WALLET_URL = 'https://wallet.test.energi.network',
    MAINNET_EXPLORER_URL = 'https://explorer.energi.network',
    TESTNET_EXPLORER_URL = 'https://explorer.test.energi.network',
    TESTNET_DISCORD_REROUTE_SERVER = 'http://staging.energi.software:3100/receive',
    MAINNET_DISCORD_REROUTE_SERVER = 'http://proposal.energi.software:3100/receive',

    MAINNET_ALERTS_SERVER_URL = 'https://alerts.energi.network',
    TESTNET_ALERTS_SERVER_URL = 'https://alerts.test.energi.network',

    PRODUCTION_SITES = [ // needs to match window.location.host to determine we use the mainnet
        'nexus.energi.network',
    ];

const getProviderInfo = () => {
    const w = (typeof window !== 'undefined') && window;
    if (w && w.location && PRODUCTION_SITES.includes(w.location.host)) {
        return {
            provider: MAINNET_REMOTE_GEN3,
            networkId: MAINNET_NETWORK_ID,
            chainId: MAINNET_CHAIN_ID,
            walletUrl: MAINNET_WALLET_URL,
            explorerUrl: MAINNET_EXPLORER_URL,
            alertsUrl: MAINNET_ALERTS_SERVER_URL,
            isStaging: false,
            discordReroute: MAINNET_DISCORD_REROUTE_SERVER,
        };
    }

    return {
        provider: TESTNET_REMOTE_GEN3,
        networkId: TESTNET_NETWORK_ID,
        chainId: TESTNET_CHAIN_ID,
        walletUrl: TESTNET_WALLET_URL,
        explorerUrl: TESTNET_EXPLORER_URL,
        alertsUrl: TESTNET_ALERTS_SERVER_URL,
        isStaging: true,
        discordReroute: TESTNET_DISCORD_REROUTE_SERVER,
    };
};

const providerInfo = getProviderInfo();

module.exports = providerInfo;
