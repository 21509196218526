import Web3 from 'web3';
import energiExtension from '@energi/web3-ext';
import {createEvent, createStore} from 'effector';
import networkOptions from '../lib/constants/network-info';
import localStorageInstance from '../lib/helpers/localstorage';
import masternodeStats from '../lib/helpers/masternode-stats';
import smartContractMasternode from '../lib/smart-contracts/masternode';
import blockPolling from './block-polling';

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const $lastBlock = blockPolling.$lastBlock;

let polling = false,
    fetching = false,
    lastUpdatedBlock;

const $data = createStore([]),
    $stats = createStore({}),
    $isFetching = createStore(''),
    $isOldState = createStore(true),
    $favorites = createStore({}),
    $currentPayout = createStore(0),
    on = {
        setList: createEvent(),
        setStats: createEvent(),
        setFavorites: createEvent(),
        setOld: createEvent(),
        setFetch: createEvent(),
        setCurrentPayout: createEvent(),
    },
    run = {}, // Object for effects
    web3 = new Web3(networkOptions.networkAddress),
    masternodeContract = smartContractMasternode(web3);

energiExtension.extend(web3);

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
 * Logs error to the console
 *
 * @method logError
 * @param error {Object} with error property
 * @protected
 * @since 0.4.0
 */
const consoleError = ({error}) => {
    console.error('Error fetching Masternodes list:', error);
};

/**
 * Ensures the masternodelist has only valid entries
 *
 * @method getCleanMasternodeList
 * @protected
 * @since 0.10.9
 */
const getCleanMasternodeList = async () => {
    const masternodes = await web3.masternode.listMasternodes();
    return masternodes.filter(m => !!m);
};

/**
 * Gets Masternode list from the network and stores it to the $data store.
 *
 * @method fetchMasternodeList
 * @protected
 * @since 0.4.0
 */
const preparedMasternodes = async masternodes => {
    let keys, key, favorite;
    const favorites = $favorites.getState();
    masternodes = masternodes.filter(mn => !!mn);

    // add favorites that are not part of the masternode list -> we need to see them on top as being inactive:
    keys = Object.keys(favorites);
    for (key of keys) {
        favorite = favorites[key];
        const index = masternodes.findIndex(mn => mn.masternode === favorite.masternode);
        if (index === -1) {
            // favorite not in masternode list
            favorite.isActive = false;
            favorite.isAlive = false;
            favorite.noRank = true;
            // because the amount of collateral is not accureate, we need to refresh:
            favorite.collateral = (await web3.masternode.collateralBalance(favorite.owner)).balance;
            masternodes.push(favorite); // put to the end: we don't want to mess up the rank of the other masternodelist
        }
    }
    return masternodes;
};

const setInactiveMasternodesAgeZero = (masternodes, blockHeight) => {
    masternodes.forEach(masternode => {
        if (!masternode.isActive) {
            masternode.announcedBlock = blockHeight + 1; // always make it appear as zero
        }
    });
};

/**
 * Gets Masternode list from the network and stores it to the $data store.
 *
 * @method fetchMasternodeList
 * @protected
 * @since 0.4.0
 */
const fetchMasternodeList = async () => {
    const currentBlock = $lastBlock.getState();
    let masternodes, stats, lastUpdate, currentPayout;
    // first try to see if another window just updated the masternode data.
    // if so, then we will use that instead of making another request:
    if (localStorageInstance) {
        lastUpdate = localStorageInstance.getObject('masternodeupdate');
        if (lastUpdate && (lastUpdate === currentBlock)) {
            // new request is not granted: use information from localstorage
            stats = localStorageInstance.getObject('masternodestats', stats);
            stats && on.setStats(stats);
            masternodes = localStorageInstance.getObject('masternodes', masternodes);
            masternodes && on.setList(await preparedMasternodes(masternodes));
            if (stats && masternodes) {
                return;
            }
        }
    }
    if (!fetching) {
        fetching = true;
        on.setFetch(true);
        try {
            lastUpdatedBlock = currentBlock;
            // set as quick as posible: other windows might read in short term
            if (localStorageInstance) {
                localStorageInstance.setObject('masternodeupdate', currentBlock);
            }
            masternodes = await getCleanMasternodeList();
            setInactiveMasternodesAgeZero(masternodes, currentBlock);
            masternodes && on.setList(await preparedMasternodes(masternodes));
            stats = masternodeStats(masternodes);
            stats && on.setStats(stats);
            if (localStorageInstance && stats && masternodes) {
                localStorageInstance.setObject('masternodestats', stats);
                localStorageInstance.setObject('masternodes', masternodes);
            }
        }
        catch (error) {
            consoleError({error}); // pass error inside an object, just as run.startPolling.fail.watch does
            if (localStorageInstance && lastUpdate) {
                localStorageInstance.setObject('masternodeupdate', lastUpdate);
            }
        }
        fetching = false;
        on.setFetch(false);
    }
    currentPayout = await masternodeContract.getCurrentPayout();
    on.setCurrentPayout(currentPayout || 0);
};

const fetchInitial = async () => {
    let stats, masternodes, lastUpdate, favorites, currentPayout;
    try {
        fetching = true;
        on.setFetch(true);
        lastUpdatedBlock = await web3.eth.getBlockNumber(); // <-- do not get from $lastBlock.getState(), is not intitated yet
        if (localStorageInstance) {
            stats = localStorageInstance.getObject('masternodestats');
            stats && on.setStats(stats);
            favorites = localStorageInstance.getObject('masternodefavorites');
            favorites && on.setFavorites(favorites);
            masternodes = localStorageInstance.getObject('masternodes');
            masternodes && on.setList(await preparedMasternodes(masternodes));
            if (stats && masternodes) {
                lastUpdate = localStorageInstance.getObject('masternodeupdate');
            }
            localStorageInstance.setObject('masternodeupdate', lastUpdatedBlock);
        }
        // we might want to refresh data when no local storage, or when local storage has old data
        if (!lastUpdate || lastUpdate !== lastUpdatedBlock) {
            if (lastUpdate) {
                on.setOld(true);
            }
            masternodes = await getCleanMasternodeList();
            setInactiveMasternodesAgeZero(masternodes, lastUpdatedBlock);
            masternodes && on.setList(await preparedMasternodes(masternodes));
            stats = masternodeStats(masternodes);
            stats && on.setStats(stats);
            if (stats && masternodes) {
                localStorageInstance.setObject('masternodestats', stats);
                localStorageInstance.setObject('masternodes', masternodes);
            }
        }
        currentPayout = await masternodeContract.getCurrentPayout();
        on.setCurrentPayout(currentPayout || 0);
    }
    catch (error) {
        consoleError({error}); // pass error inside an object, just as run.startPolling.fail.watch does
    }
    fetching = false;
    on.setFetch(false);
    on.setOld(false);
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

$isOldState.on(on.setOld, (state, status) => status);
$isFetching.on(on.setFetch, (state, status) => status);

// Wait for setList Event and put new list in $data
$data.on(on.setList, (state, data) => data);
// Wait for setStats Event and put new stats in $stats
$stats.on(on.setStats, (state, stats) => stats);
// Wait for setFavorites Event and put new favorites in $favorites
$favorites.on(on.setFavorites, (state, favorites) => {
    if (localStorageInstance) {
        localStorageInstance.setObject('masternodefavorites', favorites);
    }
    return favorites;
});
// Wait for setCurrentPayout Event and put new currentPayout in $currentPayout
$currentPayout.on(on.setCurrentPayout, (state, currentPayout) => currentPayout);

// Watch $lastBlock for changes in the blocknumber: if polling is activated then fetch new masterndoe list
$lastBlock.watch(() => {
    // new block
    if (polling) {
        fetchMasternodeList();
    }
});

// set <polling< variable true: this will continue polling.
// Also onetim fetchMasternodeList if the current lastBlock exceeded the lastUpdatedBlock
run.startPolling = () => {
    polling = true;
    if (lastUpdatedBlock !== $lastBlock.getState()) {
    // immediately fetch new data, we don't want to wait for a new block
        fetchMasternodeList();
    }
};

// set <polling< variable false: this will halt polling.
run.stopPolling = () => {
    polling = false;
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

// first time retrieval list:
fetchInitial();

if (!localStorageInstance) {
    console.warn('localstorage not available');
}

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
 * @property masternodeListing
 *     @property {Array} masternodeListing.$data the store that holds the Masternode List data
 *     @property {Array} masternodeListing.$stats Masternode statistics
 *       @property {Bool} masternodeListing.$fetchingState the store that holds the Masternode List data
 *     @property {Bool} masternodeListing.$isOldState Masternode statistics
 *     @property {Array} masternodeListing.$favorites user favorites, from localStorage
 *     @property {Object} masternodeListing.on
 *         @property {Function} masternodeListing.on.setList sets a new list to $data
 *         @property {Function} masternodeListing.on.setStats sets a new stats to $stats
 *     @property {Object} masternodeListing.run
 *         @property {Function} masternodeListing.run.startPolling starts polling for updated masternode list data
 *         @property {Function} masternodeListing.run.stopPolling stops polling masternode list data
 * @since 0.4.0
 */
const masternodeListing = {
    $data,
    $stats,
    $favorites,
    $isFetching,
    $isOldState,
    $currentPayout,
    on,
    run,
};

export default masternodeListing;
