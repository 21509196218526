const translationsEN = require('../languages/en.json');

const SUBREGEX  = /\{\s*([^|}]+?)\s*(?:\|([^}]*))?\s*\}/g;

const substituteStr = (str, obj) =>
    str.replace(SUBREGEX, (_, key) =>
        (obj[key] === undefined) ? '' : obj[key]);

const translate = (translations, textIdentifier, sentence, values) => {
    // translationsEN is always up to date, because becomes part of the code.
    let translated = translations[textIdentifier] || translationsEN[textIdentifier];
    if (!translated) {
        return '';
    }
    if (sentence) {
        translated = translated[0].toUpperCase() + translated.substr(1);
    }
    if (translated.indexOf('{') !== -1) {
        // contains values in translation string
        translated = substituteStr(translated, values);
    }
    return translated;
};

const translateMarkDown = (translationsMD, textIdentifier) => {
    textIdentifier = textIdentifier.toLowerCase();
    let translated = translationsMD[textIdentifier];
    if (!translated) {
        // we cannot require a markdown file and transform it on the client: that would
        // lead to an overkill of code on the client. Instead, return an empty string.
        // On a next refresh of the page, the markdown should be part of this.props.
        return '';
    }
    return translated;
};

const init = props => {
    let translations = props.translations || (props.pageContext && props.pageContext.translations),
        translationsMD = props.translationsMD || (props.pageContext && props.pageContext.translationsMD);

    if (!translations) {
        translations = {};
        console.warn('missing pageContext.translations');
    }
    if (!translationsMD) {
        translationsMD = {};
        console.warn('missing pageContext.translationsMD');
    }
    return {
        t: translate.bind(null, translations),
        md: translateMarkDown.bind(null, translationsMD),
    };
};

module.exports = {
    init
};
