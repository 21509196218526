import React from 'react';
import {Link} from 'gatsby';
import {IconButton} from '@rmwc/icon-button';
import translations from '../lib/translations';
import languageSelection from '../globals/language-selection';

const LanguageSelectionScreen = props => {
    const translate = translations.init(props),
        languages = props.languages || props.pageContext.languages,
        langInfo = props.langInfo || props.pageContext.langInfo,
        pathname = props.location.pathname,
        currentLang = langInfo.lang,
        path = pathname.startsWith('/' +  currentLang + '/') ? pathname.substr(currentLang.length + 1) : pathname;

    const getLanguages = () => {
        return languages.map((info, i) => {
            let url = path;
            if (info.lang !== 'en') {
                url = '/' + info.lang + url;
            }
            if (info.lang === currentLang) {
                return (
                    <li key={i}>
                        <div className="lang-container" onClick={languageSelection.on.hide}>
                            <h1>{info.language}</h1>
                            {info.country}
                        </div>
                    </li>
                );
            }
            // else create a link:
            return (
                <li key={i}>
                    <Link onClick={languageSelection.on.hide.bind(null, true)} to={url}>
                        <div className="lang-container">
                            <h1>{info.language}</h1>
                            {info.country}
                        </div>
                    </Link>
                </li>
            );
        });
    };

    return (
        <>
            <div className="shim-layer" />
            <div className="pure-u language-selection" onClick={e => (e.target === e.currentTarget) && languageSelection.on.hide()}>
                <div className="pop-up">
                    <div className="top">
                        <IconButton
                            icon="close"
                            onClick={languageSelection.on.hide} />
                        <span className="choose">{translate.t('CHOOSE_YOUR_LANGUAGE', true)}</span>
                    </div>
                    <ul>
                        {getLanguages()}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default LanguageSelectionScreen;
