import React from 'react';

import {componentFactory} from '@rmwc/base';
import {withRipple} from '@rmwc/ripple';
import {ButtonIcon} from '@rmwc/button';

import './styles.scss';

/**
 * The AnchorButton component, just as Button is created by RMWC:
 * https://github.com/jamesmfriedman/rmwc/blob/master/src/button/index.tsx
 */
export const AnchorButton = withRipple({
    surface: false
})(
    componentFactory({
        displayName: 'AnchorButton',
        tag: 'a',
        classNames: props => [
            'mdc-button nrg-anchor-button',
            {
                'mdc-button--dense': props.dense,
                'mdc-button--raised': props.raised,
                'mdc-button--unelevated': props.unelevated,
                'mdc-button--outlined': props.outlined
            }
        ],
        consumeProps: [
            'dense',
            'unelevated',
            'outlined',
            'primary',
            'accent',
            'raised',
            'unbounded'
        ],
        render: (
            {
                icon,
                trailingIcon,
                label,
                children,
                danger,
                disabled,
                href,
                target,
                /* eslint-disable-next-line no-unused-vars */
                raised,
                ...rest
            },
            ref,
            Tag
        ) => {
            if (danger) {
                const existingStyle = rest.style || {};
                const dangerStyle = {
                    '--mdc-theme-primary': 'var(--mdc-theme-error)',
                    '--mdc-theme-on-primary': 'var(--mdc-theme-on-error)'
                };
                rest.style = {
                    ...dangerStyle,
                    ...existingStyle
                };
            }

            if (disabled) {
                href = undefined;
            }
            else if (!target) {
                target = '_blank';
            }

            return (
                <Tag
                    {...rest}
                    disabled={disabled}
                    href={href}
                    ref={ref}
                    target={target} >
                    {!!icon && <ButtonIcon icon={icon} />}
                    <span className="mdc-button__label">
                        {label}
                        {children}
                    </span>
                    {!!trailingIcon && <ButtonIcon icon={trailingIcon} />}
                </Tag>
            );
        }
    })
);

const defaultExport = {};

export default defaultExport;
