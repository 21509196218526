import {createEffect, createStore, createEvent} from 'effector';
import {delay} from 'itsa-utils';
import libMetamask from '../lib/helpers/metamask';
import networkOptions from '../lib/constants/network-info';
import localStorageInstance from '../lib/helpers/localstorage';
import isMobile from '../lib/helpers/detect-mobile';
import masternodeOwnerStore from './masternode-owner';

const DELAY = 1000;

let styleNode, scrollX, scrollY;

/* ******************************************************************************************************** */

const $installed = createStore(false),
    $metamask = createStore({}),
    $connected = createStore(false),
    $logoutFormVisible = createStore(false),
    $walletSelectionVisible = createStore(false),
    $correctNetwork = createStore(null),
    $chainId = createStore(''),
    $address = createStore(null),
    $accounts = createStore([]),
    on = {
        toggleWalletLogout: createEvent(),
        hideWalletLogout: createEvent(),
        hideWalletSelection: createEvent(),
        showWalletSelection: createEvent(),
        connect: async () => {
            const accounts = await libMetamask.connect();
            if (accounts && accounts[0]) {
                localStorageInstance.setSimpleType('walletConnected', true);
            }
            await checkNetwork();
            return $correctNetwork.getState();
        },
        disconnect: async () => {
            $logoutFormVisible.setState(false);
            localStorageInstance.setSimpleType('walletConnected', false);
            await libMetamask.disconnect();
            checkNetwork();
        },
    };

/* ******************************************************************************************************** */

/**
* Polls for metamask status. Uses createEffect, which means that
* on changes, the $stores will be updated.
*
* @method createEffect
* @protected
* @since 0.11.0
*/
const checkNetwork = createEffect({
    async handler() {
        let chainId, correctNetwork, address, connected, metamask, accounts;
        nextCheck(); // do not await!

        const isInstalled = await libMetamask.isInstalled();

        if (!isInstalled) {
            return {
                correctNetwork: false,
                chainId: '',
                address: null,
                connected: false,
                installed: false,
                metamask: null,
                accounts: []
            };
        }

        // according to MetaMask docs, we should only connect when a user actively presses a button
        // however, many dApps (like Uniswap) connect during startup. Therefore, so do we.

        if (!(await libMetamask.isConnected()) && localStorageInstance.getSimpleType('walletConnected')) {
            await libMetamask.connect();
        }

        chainId = await libMetamask.getChainId();
        correctNetwork = chainId === networkOptions.chainId;
        metamask = await libMetamask.getMetaMask();
        connected = await libMetamask.isConnected();
        if (!correctNetwork) {
            return {
                correctNetwork,
                chainId,
                address: null,
                connected,
                installed: true,
                metamask,
                accounts: []
            };
        }

        if (connected) {
            address = await libMetamask.getAddress();
            accounts = await libMetamask.getAccounts();
            masternodeOwnerStore.on.changeAddress(address);
        }
        else {
            address = null;
            accounts = [];
        }

        return {
            correctNetwork,
            chainId,
            address,
            connected,
            installed: true,
            metamask,
            accounts
        };
    },
});

/**
* Logs error to the console
*
* @method logError
* @param error {Object} with error property
* @protected
* @since 0.11.0
*/
const logError = ({error}) => {
    console.error('Error MetaMask:', error);
};

/**
* Initiates a next metamask status check after a delay of 1 seconds.
*
* @method nextCheck
* @protected
* @since 0.4.0
*/
const nextCheck = async () => {
    await delay(DELAY);
    checkNetwork();
};

/* ******************************************************************************************************** */

// On fail show warning
checkNetwork.fail.watch(logError);

// Wait for polling effect and put it in $lastBlock
$correctNetwork.on(checkNetwork.done, (state, {result}) => result.correctNetwork);
$chainId.on(checkNetwork.done, (state, {result}) => result.chainId);
$address.on(checkNetwork.done, (state, {result}) => result.address);
$connected.on(checkNetwork.done, (state, {result}) => result.connected);
$installed.on(checkNetwork.done, (state, {result}) => result.installed);
$metamask.on(checkNetwork.done, (state, {result}) => result.metamask);
$accounts.on(checkNetwork.done, (state, {result}) => result.accounts);

$logoutFormVisible.on(on.toggleWalletLogout, state => {
    if (state) {
        document.documentElement.removeAttribute('data-shimlayer');
    }
    else if (isMobile) {
        document.documentElement.setAttribute('data-shimlayer', 'true');
    }
    return !state;
});

$logoutFormVisible.on(on.hideWalletLogout, () => {
    document.documentElement.removeAttribute('data-shimlayer');
    return false;
});

$walletSelectionVisible.on(on.hideWalletSelection, () => {
    window.requestAnimationFrame(() => {
        // execute this when the next css rendering has finished:
        window.scrollTo(scrollX, scrollY);
    });

    // update the css, which will tricker requestAnimationFrame above:
    document.documentElement.removeAttribute('data-shimlayer');

    return false;
});
$walletSelectionVisible.on(on.showWalletSelection, () => {
    if (!styleNode) {
        createCssNode();
    }
    scrollX = window.scrollX;
    scrollY = window.scrollY;
    updateCssNode(scrollY);
    document.documentElement.setAttribute('data-shimlayer', 'true');
    return true;
});

// start with connecting and checking network
checkNetwork();

/* ******************************************************************************************************** */

/**
* Creates a css style node that will be used to set the css variable --language-scroll
*
* @method createCssNode
* @protected
* @since 0.11.0
*/
const createCssNode = () => {
    styleNode = document.createElement('style');
    styleNode.setAttribute('type', 'text/css');
    document.head.appendChild(styleNode);
};

/**
* Sets the css variable --language-scroll to the current scroll-y position
* This is needed to make sure that the background page holds it scroll position when
* its position is set to fixed.
*
* @method updateCssNode
* @protected
* @since 0.11.0
*/
const updateCssNode = (scrollY) => {
    // take current scroll position and set it to css variable
    // this css variable is used within _externals.scss like this:
    /*
        html[data-shimlayer="true"] {
            .subpage, .homepage {
                position: fixed;
                top: var(--language-scroll)px;
            }
        }
    */
    styleNode.textContent = `:root {--language-scroll: -${scrollY}px}`;
};

/* ******************************************************************************************************** */

const metamask = {
    $installed,
    $correctNetwork,
    $chainId,
    $metamask,
    $connected,
    $address,
    $accounts,
    $logoutFormVisible,
    $walletSelectionVisible,
    on
};

export default metamask;
