const Web3 = require('web3');

const getStats = masternodes => {
    const activeMasternodes = masternodes.filter(mn => mn.isActive);

    const [activeCollateral, totalCollateral] = masternodes.reduce((acc, mn) => [
        mn.isActive ? acc[0].add(mn.collateral) : acc[0], // active
        acc[1].add(mn.collateral),                        // total
    ], [Web3.utils.toBN(0), Web3.utils.toBN(0)]);

    return {
        active: activeMasternodes.length,
        activeCollateral,
        total: masternodes.length,
        totalCollateral,
    };
};

module.exports = getStats;
