const getLanguageUrl = (langInfo, path) => {
    const lang = (langInfo && langInfo.lang) || 'en';
    let url = path;
    if (lang !== 'en') {
        url = '/' + lang + url;
    }
    return url;
};

const removeLanguageUrl = (langInfo, path) => {
    const lang = (langInfo && langInfo.lang) || 'en';
    let url = path;
    if (lang !== 'en') {
        url = url.substr(lang.length + 1);
    }
    return url;
};

module.exports = {
    getLanguageUrl,
    removeLanguageUrl
};
