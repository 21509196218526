import Web3 from 'web3';
import {delay} from 'itsa-utils';
import {createEffect, createStore} from 'effector';
import networkOptions from '../lib/constants/network-info';
import localStorageInstance from '../lib/helpers/localstorage';

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const DELAY = 10000,
    MAX_DIFFICULTIES_BLOCKS = 120, // store max 120 block with a difficulty
    initialDifficulties = (localStorageInstance && localStorageInstance.getObject('blockdifficulties')) || [];

const $lastBlock = createStore(0),
    $difficulties = createStore(initialDifficulties),
    web3 = new Web3(networkOptions.networkAddress);

let initializing = true; // needed to prevent locking during startup because a browser closed while lastdifficulty.busy was true

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* Polls for the latest block number. Uses createEffect, which means that
* on changes, the block number gets stored inside the $lastBlock store.
*
* @method pollBlockNumber
* @protected
* @since 0.4.0
*/
const pollBlockNumber = createEffect({
    async handler() {
        const lastBlock = await web3.eth.getBlockNumber();
        nextPoll(); // do not await!
        // store block difficulty, before returning (and force updating any pages) of the blocknr:
        // only if localstorage is enabled, otherwise the network will be overloaded with requests
        // and only if ($lastBlock.getState() !== lastBlock) -> in case the blocknr changes
        if (localStorageInstance && ($lastBlock.getState() !== lastBlock)) {
            await storeDifficulty(lastBlock);
        }
        return lastBlock;
    },
});

/**
* Logs error to the console
*
* @method logError
* @param error {Object} with error property
* @protected
* @since 0.4.0
*/
const logError = ({error}) => {
    console.error('Error fetching lastBlock:', error);
};

/**
* Initiates a next pollBlockNumber after a delay of 10 seconds.
*
* @method nextPoll
* @protected
* @since 0.4.0
*/
const nextPoll = async () => {
    await delay(DELAY);
    pollBlockNumber();
};

/**
* Makes sure we have the difficulties of the latest MAX_DIFFICULTIES_BLOCKS blocks
* Will be stored in localstorage as "blockdifficulties", the blocks are top down
*
* @method storeDifficulty
* @protected
* @since 0.7.12
*/
const storeDifficulty = async blockNr => {
    // first check if we need an update.
    // keep in mind that another browsertab could be busy at this very moment, so we need to use 'lastdifficulty'
    let block, difficulties, index, lastDifficulty, mostRecentBlock, busy;
    lastDifficulty = localStorageInstance.getObject('lastdifficulty');
    if (lastDifficulty) {
        mostRecentBlock = lastDifficulty.block;
        busy = lastDifficulty.busy;
    }
    if (initializing) {
        busy = false;
    }

    // if block is up to date, or currently busy updating, then exit:
    if ((blockNr === mostRecentBlock) || busy) {
        // already up to date, or busy updating
        return;
    }
    initializing = false;

    // save lastDifficulty to prevent simultanious updates:
    lastDifficulty = {
        block: blockNr,
        busy: true
    };
    localStorageInstance.setObject('lastdifficulty', lastDifficulty);

    // add new blocks, both index and blockNr will change during the loop:
    difficulties = localStorageInstance.getObject('blockdifficulties') || [];
    index = 0;
    while ((mostRecentBlock !== blockNr) && (index < MAX_DIFFICULTIES_BLOCKS)) {
        block = await web3.eth.getBlock(blockNr);
        // insert item, newest block at the top
        difficulties.splice(index++, 0, {
            block: blockNr,
            difficulty: block.difficulty
        });
        blockNr--;
    }

    // in case it has been a while, we ended up with old items. They need to be removed:
    if (difficulties.length > MAX_DIFFICULTIES_BLOCKS) {
        difficulties.length = MAX_DIFFICULTIES_BLOCKS;
    }

    // store difficulties in local storage
    localStorageInstance.setObject('blockdifficulties', difficulties);
    $difficulties.setState(difficulties);
    // finished, enable next calculation:
    lastDifficulty.busy = false;
    localStorageInstance.setObject('lastdifficulty', lastDifficulty);
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

// On fail show warning
pollBlockNumber.fail.watch(logError);

// Wait for polling effect and put it in $lastBlock
$lastBlock.on(pollBlockNumber.done, (state, {result}) => {

    return result;
});

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

// start with polling
pollBlockNumber();

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* @property {Number} last mined Blocknumber on the network
* @since 0.4.0
*/

const blockPolling = {
    $lastBlock,
    $difficulties,
};

export default blockPolling;
