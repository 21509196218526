import {createEvent, createStore} from 'effector';

let needsPageloadScrollReset = 0,
    styleNode, scrollX, scrollY;

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

const $visible = createStore(false);
const on = {
    open: createEvent(),
    hide: createEvent()
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* Creates a css style node that will be used to set the css variable --language-scroll
*
* @method createCssNode
* @protected
* @since 0.7.12
*/
const createCssNode = () => {
    styleNode = document.createElement('style');
    styleNode.setAttribute('type', 'text/css');
    document.head.appendChild(styleNode);
};

/**
* Sets the css variable --language-scroll to the current scroll-y position
* This is needed to make sure that the background page holds it scroll position when
* its position is set to fixed.
*
* @method updateCssNode
* @protected
* @since 0.7.12
*/
const updateCssNode = (scrollY) => {
    // take current scroll position and set it to css variable
    // this css variable is used within _externals.scss like this:
    /*
        html[data-shimlayer="true"] {
            .subpage, .homepage {
                position: fixed;
                top: var(--language-scroll)px;
            }
        }
    */
    styleNode.textContent = `:root {--language-scroll: -${scrollY}px}`;
};

/**
* Callback on page re rendering. Will reset the scroll position, but only if needsPageloadScrollReset
* got a value by the language selector when it got hidden. In that case, needsPageloadScrollReset is initially 1.
* Thise method will only reset the scroll position on needsPageloadScrollReset == =2, because it waits
* for a follow up rerendering: the first was when the language selector got hidden, the second
* when the page got rendered with a new language.
*
* @method resetScroll
* @protected
* @since 0.7.12
*/
const resetScroll = () => {
    // in case another language is selected, we will need to set the scroll again, once the page is loaded
    // note that resetScroll will be triggered on an render update, which will appear twice!
    // first time when language selector hides,
    // the second time when the new language is rendered.
    if (needsPageloadScrollReset === 1) {
        // language selector is hdden: increase needsPageloadScrollReset and wait for the next rendering
        needsPageloadScrollReset = 2;
    }
    else if (needsPageloadScrollReset === 2) {
        // page is rendered in new language
        window.requestAnimationFrame(() => {
            // execute this when the next css rendering has finished:
            window.scrollTo(scrollX, scrollY);
        });
        needsPageloadScrollReset = 0;
    }
};

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

// Handler to set visible true
$visible.on(on.open, () => {
    if (!styleNode) {
        createCssNode();
    }
    scrollX = window.scrollX;
    scrollY = window.scrollY;
    updateCssNode(scrollY);
    document.documentElement.setAttribute('data-shimlayer', 'true');
    return true;
});

// Handler to set visible false
$visible.on(on.hide, (x, pageload) => {
    // we will remove the attribute 'data-shimlayer' and the reset the scroll position.
    // note we need to be sure that the dom uses the updated css before we can set the scroll position
    // otherwise we might end up setting the scroll position while the element is stil fixed, which will fail to reset

    window.requestAnimationFrame(() => {
        // execute this when the next css rendering has finished:
        window.scrollTo(scrollX, scrollY);
        // in case another language is selected, we will need to set the scroll again, once the page is loaded
        // note that resetScroll will be triggered on an render update, which will appear twice!
        // first time when language selector hides,
        // the second time when the new language is rendered.
        // therefore, we set the value to 1, BUT resetScroll will reset scroll position on needsPageloadScrollReset === 2
        needsPageloadScrollReset = (pageload === true) ? 1 : 0;
    });

    // update the css, which will tricker requestAnimationFrame above:
    document.documentElement.removeAttribute('data-shimlayer');

    return false;
});

/* ********************************************************************************************************** */
/* ********************************************************************************************************** */

/**
* @property {Number} last mined Blocknumber on the network
* @since 0.6.5
*/

const langSelection = {
    $visible,
    on,
    resetScroll,
};

export default langSelection;
