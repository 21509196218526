const Web3 = require('web3');
const isMobile = require('./detect-mobile');

let metamask;

(function(win) {
    let connectPromise;

    metamask = {
        isInstalled: () => {
            if (!isMobile) {
                return Promise.resolve(!!win.ethereum && win.ethereum.isMetaMask);
            }
            return new Promise (resolve => {
                const handleEthereumCheck = () => {
                    resolve(!!win.ethereum && win.ethereum.isMetaMask);
                };

                win.addEventListener && win.addEventListener('ethereum#initialized', handleEthereumCheck, {
                    once: true,
                });
                // If the event is not dispatched by the end of the timeout,
                // the user probably doesn't have MetaMask installed.
                setTimeout(handleEthereumCheck, 3000); // 3 seconds
            });
        },
        getMetaMask: async () => {
            const isInstalled = await metamask.isInstalled();
            return isInstalled && win.ethereum;
        },
        isConnected: async () => {
            if (!connectPromise) {
                return false;
            }
            return connectPromise.then(() => true).catch(() => false);
        },
        getChainId: async () => {
            const mm = await metamask.getMetaMask();
            if (!mm) {
                return;
            }
            return Web3.utils.hexToNumber(mm.chainId);
        },
        getAddress: async () => {
            if (!(await metamask.isConnected())) {
                return;
            }
            const mm = await metamask.getMetaMask();
            return mm.selectedAddress;
        },
        getAccounts: async forceRefresh => {
            if (!(await metamask.isConnected())) {
                return;
            }
            return metamask.connect(forceRefresh);
        },
        sendTx: async rawTx => {
            const mm = await metamask.getMetaMask();
            if (!mm) {
                throw new Error('MetaMask is not installed');
            }

            // METAMASK may flash an empty form very quickly, this seems to be a known bug:
            // https://github.com/MetaMask/metamask-extension/issues/9345
            // still works fine
            return mm.request({
                method: 'eth_sendTransaction',
                params: [rawTx],
            });
        },
        disconnect: async () => {
            if (connectPromise) {
                await connectPromise;
            }
            connectPromise = null;
        },
        connect: async forceRefresh => {
            // never start a connection when another is currently pending!
            if (connectPromise && !forceRefresh) {
                return connectPromise;
            }
            const mm = await metamask.getMetaMask();
            connectPromise = mm.request({method: 'eth_requestAccounts'});
            return connectPromise;
        }
    };
})(typeof global !== 'undefined' ? global : /* istanbul ignore next */ this);

module.exports = metamask;
