import React, {useState, useEffect} from 'react';
import {useStore} from 'effector-react';
import {Button} from '@rmwc/button';
import {IconButton} from '@rmwc/icon-button';
import {Snackbar, SnackbarAction} from '@rmwc/snackbar';
import translations from '../lib/translations';
import metamaskStore from '../globals/metamask';
import imgMetaMask from '../images/metamask.svg';
import shortenAddress from '../lib/helpers/shorten-address';
import copyToClipboard from '../lib/helpers/clipboard';
import isMobile from '../lib/helpers/detect-mobile';
import {explorerUrl as networkExplorerUrl} from '../lib/constants/network-info';
import iconExplorer from '../images/arrow-north-east.svg';

const ADDRESS_CHARS = 6,
    TIMEOUT_SNACKBAR_COPY_CLIPBOARD = 2000;

const checkInside = node => {
    if (node === document.body) {
        return false;
    }
    const dataId = node.getAttribute('data-id');
    return (dataId === 'wallet-btn') || (dataId === 'wallet-logout-container') || checkInside(node.parentNode);
};

const WalletLogoutForm = props => {
    let disconnectBtn;
    const translate = translations.init(props),
        metamaskAddress = useStore(metamaskStore.$address),
        [snackbarMsg, setSnackbarMsg] = useState(''),
        [style, setStyle] = useState({}),
        explorerUrl = networkExplorerUrl + '/address/' + metamaskAddress + '/coin_balances';

    const closeDropdown = e => {
        const node = e.target;
        // check if the click was on or inside the container
        let noAction = checkInside(node);
        if (!noAction) {
            noAction = checkInside(node);
        }
        if (!noAction) {
            metamaskStore.on.hideWalletLogout();
        }
    };

    useEffect(() => {
        const doc = document,
            win = window;
        let buttonWidth, buttonLeft, scrollLeft, winWidth;
        document.addEventListener('mousedown', closeDropdown);
        const button = doc.querySelectorAll('[data-id="wallet-btn"]')[0];

        if (button) {
            scrollLeft = win.pageXOffset;
            winWidth = win.innerWidth;
            buttonWidth = button.offsetWidth;
            buttonLeft = button.getBoundingClientRect().left + scrollLeft;
            setStyle({
                width: buttonWidth,
                right: winWidth - (buttonLeft + buttonWidth),
                display: 'block',
            });
        }

        return () => {
            document.removeEventListener('mousedown', closeDropdown);
        };
    }, []);

    const copyMetamaskAddress = () => {
        copyToClipboard(metamaskAddress);
        document.activeElement.blur();
        setSnackbarMsg(translate.t('ADDRESS', true) + ' ' + translate.t('COPIED_CLIPBOARD'));
    };

    if (!isMobile) {
        disconnectBtn = (
            <Button
                className="border-btn btn-disconnect"
                label={translate.t('DISCONNECT')}
                onClick={metamaskStore.on.disconnect}
                raised
                ripple={false} />
        );
    }

    return (
        <>
            <div className="shim-layer only-mobile" />
            <div className="pure-u wallet-logout" data-id="wallet-logout-container" style={style}>
                <img
                    alt=""
                    className="metamask-img"
                    src={imgMetaMask} />
                <div>
                    <span>{shortenAddress(metamaskAddress, ADDRESS_CHARS)}</span>
                </div>
                <div className="btns">
                    <IconButton icon="content_copy" onClick={copyMetamaskAddress} />
                    <a
                        href={explorerUrl}
                        onClick={e => {
                            e.stopPropagation;
                            document.activeElement.blur();
                        }}
                        rel="noreferrer"
                        target="_blank">
                        <div>
                            <img
                                alt="view in explorer"
                                className="icon-explorer"
                                src={iconExplorer} />
                        </div>
                    </a>
                </div>
                {disconnectBtn}
                <Snackbar
                    action={<SnackbarAction icon="close" />}
                    leading
                    message={snackbarMsg}
                    onClose={() => setSnackbarMsg('')}
                    open={!!snackbarMsg}
                    timeout={TIMEOUT_SNACKBAR_COPY_CLIPBOARD}/>
            </div>
        </>
    );
};

export default WalletLogoutForm;
