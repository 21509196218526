import React, {useState} from 'react';
import {delay} from 'itsa-utils';
import {Icon} from '@rmwc/icon';
import {IconButton} from '@rmwc/icon-button';
import {Snackbar, SnackbarAction} from '@rmwc/snackbar';
import translations from '../lib/translations';
import metamaskStore from '../globals/metamask';
import networkInfo from '../lib/constants/network-info';
import isMobile from '../lib/helpers/detect-mobile';
import imgMetaMask from '../images/metamask.svg';

const TESTNET_NETWORK_ID = 49797,
    TIMEOUT_SNACKBAR_INVALID_NETWORK = 6000;

const EXTENTIONS = [
    {
        label: 'MetaMask',
        img: imgMetaMask,
        connect: metamaskStore.on.connect,
        appUrl: 'https://metamask.app.link/dapp/'
    }
];

const WalletSelectionScreen = props => {
    const translate = translations.init(props),
        [snackbarMsg, setSnackbarMsg] = useState('');

    const chooseExtention = async connect => {
        const correctNetwork = await connect();
        if (correctNetwork) {
            metamaskStore.on.hideWalletSelection();
        }
        else {
            setSnackbarMsg(translate.t(networkInfo.networkId === TESTNET_NETWORK_ID ? 'INVALID_NEEDS_TESTNET' : 'INVALID_NEEDS_MAINNET'));
        }
    };

    const hideSelectionScreen = async () => {
        // only on Mobile, non metamask browser
        await delay(1000); // make sure we don't remove the anchor element too soon, which would prevent the browser to navigate
        metamaskStore.on.hideWalletSelection();
    };

    const getExtensions = () => {
        return EXTENTIONS.map((extention, i) => {
            if (isMobile && extention.appUrl) {
                return (
                    <li key={i} onClick={hideSelectionScreen}>
                        <a className="wallet-container" href={extention.appUrl + window.location.hostname} rel="noreferrer" >
                            <img
                                alt=""
                                className="metamask-img"
                                src={extention.img} />
                            <span>{extention.label}</span>
                            <Icon icon="arrow_forward" />
                        </a>
                    </li>
                );
            }
            return (
                <li key={i}>
                    <div className="wallet-container" onClick={chooseExtention.bind(null, extention.connect)}>
                        <img
                            alt=""
                            className="metamask-img"
                            src={extention.img} />
                        <span>{extention.label}</span>
                        <Icon icon="arrow_forward" />
                    </div>
                </li>
            );
        });
    };

    const showHelpPage = () => {
        const windowReference = window.open(),
            url = 'https://defi.support.energi.world/hc/en-us/articles/360051190132-Set-up-MetaMask-on-Chrome-for-Energi-Mainnet';
        windowReference.location = url;
    };

    return (
        <>
            <div className="shim-layer" />
            <div className="pure-u wallet-selection" onClick={e => (e.target === e.currentTarget) && metamaskStore.on.hideWalletSelection()}>
                <div className="pop-up">
                    <div className="top">
                        <IconButton
                            icon="close"
                            onClick={metamaskStore.on.hideWalletSelection} />
                        <span className="choose">{translate.t('CHOOSE_YOUR_WALLET', true)}</span>
                    </div>
                    <ul>
                        {getExtensions()}
                    </ul>
                </div>
            </div>
            <Snackbar
                action={<SnackbarAction
                    className="invalid-network-closebtn"
                    label={translate.t('MORE_INFO')}
                    onClick={showHelpPage} />}
                className="network-error-msg"
                leading
                message={snackbarMsg}
                onClose={() => setSnackbarMsg('')}
                open={!!snackbarMsg}
                timeout={TIMEOUT_SNACKBAR_INVALID_NETWORK} />
        </>
    );
};

export default WalletSelectionScreen;
